import merge from 'lodash/merge';

import {generalNotify} from '@/utils/notifyMessages';

// This service is mostly a duplication of httpApi but with an ability to generate queryParams
// The old httpApi will be removed later when all of the logic is moved to static redux and sagas

export type QueryParams = Record<string, string | number | boolean | string[] | number[]>;

type RequestParams<U> = {
    url: string;
    method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
    data?: Record<string, any> | null;
    queryParams?: QueryParams;
    origin?: string;
    customConfig?: RequestInit;
    withToken?: boolean;
    // looks like it can be removed
    customHandler?: (response: Response) => U;
    disableGeneralNotify?: (error?: unknown) => boolean;
};

class HttpService {
    private static instance: HttpService;

    private getToken: () => Promise<string>;

    setTokenGetter(tokenGetter: () => Promise<string>) {
        this.getToken = tokenGetter;
    }

    static createInstance() {
        return new HttpService();
    }

    static getInstance() {
        if (!HttpService.instance) {
            HttpService.instance = HttpService.createInstance();
        }
        return HttpService.instance;
    }

    errHandler = (
        error: {status: string; json: () => Promise<string | {message: string; details?: {message: string}[]}>},
        disableGeneralNotify?: (error?: unknown) => boolean,
    ) => {
        return (
            error.json &&
            error
                .json()
                .then((data) => {
                    const getMessage = () => {
                        if (typeof data === 'string') {
                            return data;
                        }

                        const {message} = data;

                        if (!data.details) return message;

                        const details = `${data.details.map(({message}) => message).join('; ')}`;

                        return `${message} ${details || ''}`;
                    };

                    const getErrorData = () => {
                        const {status} = error;

                        if (typeof data === 'string') return {status};

                        return {...data, status};
                    };

                    const errorData = getErrorData();

                    (!disableGeneralNotify || !disableGeneralNotify(errorData)) &&
                        generalNotify({
                            title: 'Error',
                            message: getMessage(),
                            status: 'error',
                        });
                    throw errorData;
                })
                .catch((errorData: unknown) => {
                    if (errorData instanceof Error) {
                        throw error;
                    }
                    throw errorData;
                })
        );
    };

    async request<T>(options: RequestParams<T>): Promise<T> {
        const {
            url,
            customConfig,
            customHandler,
            data,
            disableGeneralNotify,
            method,
            origin,
            queryParams,
            withToken = true,
        } = options;

        let JwtToken = null;
        if (withToken) {
            const token = await this.getToken();

            JwtToken = {Authorization: `Bearer ${token}`};
        }

        const config: RequestInit = merge(
            {
                method,
                headers: {
                    accept: '*/*',
                    ...JwtToken,
                },
            },
            customConfig,
        );

        if (data instanceof FormData) {
            config.body = data;
        } else if (data) {
            // Assigning to config.headers directly results in TS errors
            Object.assign(config.headers, {'Content-Type': 'application/json'});
            config.body = JSON.stringify(data);
        }

        const formattedUrl = queryParams ? this.generateQueryParams(url, queryParams) : url;

        // @ts-ignore
        return fetch(`${origin || window.REACT_APP_CORE_API_URL}${formattedUrl}`, config)
            .then((response) => {
                if (!response.ok) {
                    throw response;
                } else {
                    return customHandler ? customHandler(response) : response.json();
                }
            })
            .catch((error) => this.errHandler(error, disableGeneralNotify))
            .then((response) => {
                return response;
            });
    }

    private generateQueryParams(url: string, queryParams: QueryParams) {
        const params = Object.entries(queryParams).reduce((acc, [key, paramsElement]) => {
            // null, undefined and '' are ignored
            if ((paramsElement ?? '') === '') return acc;

            const newValues = [`${key}=${paramsElement}` as const];

            return [...acc, ...newValues];
        }, [] as `${string}=${string}`[]);

        if (!params.length) return url;

        return `${url}?${params.join('&')}`;
    }

    get = <R>(params: RequestParams<R>) => this.request<R>({...params, method: 'GET', data: undefined});
    post = <R>(params: RequestParams<R>) => this.request<R>({...params, method: 'POST'});
    patch = <R>(params: RequestParams<R>) => this.request<R>({...params, method: 'PATCH'});
    put = <R>(params: RequestParams<R>) => this.request<R>({...params, method: 'PUT'});
    delete = <R>(params: RequestParams<R>) => this.request<R>({...params, method: 'DELETE'});
}
const httpApi = HttpService.getInstance();
export default httpApi;
